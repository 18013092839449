import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;

//require('foundation-sites');
import './lib/foundation-explicit-pieces';


$(document).foundation();

$(function() {
	var today = new Date();
	var dd = String(today.getDate()).padStart(2, '0');
	var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = today.getFullYear();
	var today_dd_mm_yyyy = dd + '-' + mm + '-' + yyyy;
	var today_mm_yyyy = mm + '-' + yyyy;
	
	
	$( '.date-picker:not(.date-picker-months-only,.date-today)' ).fdatepicker({
		format: 'dd-mm-yyyy',
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
	});
	
	$( '.date-picker-months-only' ).fdatepicker({
		//initialDate: '02-12-1989',
		format: 'mm-yyyy',
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
		startView: 'year',
		minView: 'year'
	});
	
	$( '.date-today' ).fdatepicker({
		initialDate: today_dd_mm_yyyy,
		startDate: today_dd_mm_yyyy,
		endDate: today_dd_mm_yyyy,
		format: 'dd-mm-yyyy',
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
	});

	function age( birthday ) {
		var birthday = new Date( birthday );
		return new Number( (new Date().getTime() - birthday.getTime() ) / 31536000000).toFixed( 0 );
	}
	//date-calculate-contributions
	
	function setContributionsDropdownByAge( user_age ) {
		if ( user_age < 30 ) {
			// Show up to 5%
			console.log( user_age + ' - Under 30' );
			$('.dropdown-calculate-contributions option').remove();
			$( '.dropdown-calculate-contributions' ).append( '<option value="3%">3%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="3.5%">3.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="4%">4%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="4.5%">4.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="5%">5%</option>');
		} else if ( user_age >= 30 && user_age < 45 ) {
			// Show up to 6%
			console.log( user_age + ' - 30 and over' );
			$('.dropdown-calculate-contributions option').remove();
			$( '.dropdown-calculate-contributions' ).append( '<option value="3%">3%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="3.5%">3.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="4%">4%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="4.5%">4.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="5%">5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="5.5%">5.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="6%">6%</option>');
		} else if ( user_age >= 45 ) {
			// Show up to 8%
			console.log( user_age + ' - 45 and over' );
			$('.dropdown-calculate-contributions option').remove();
			$( '.dropdown-calculate-contributions' ).append( '<option value="3%">3%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="3.5%">3.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="4%">4%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="4.5%">4.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="5%">5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="5.5%">5.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="6%">6%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="6.5%">6.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="7%">7%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="7.5%">7.5%</option>');
			$( '.dropdown-calculate-contributions' ).append( '<option value="8%">8%</option>');
		}
	}
	
	$( '.date-calculate-contributions' ).fdatepicker().on( 'changeDate', function (ev) {
		//alert( dateDiff( ev.date.valueOf(), today ) );
		var user_age = age( ev.date );
		//alert( ev.date );
		setContributionsDropdownByAge( user_age );
	});
	
	/*$( '#date-of-birth' ).on( 'change', function (ev) {
		//var user_age = age( $( this ).val() );
		//alert( user_age );
		//setContributionsDropdownByAge( user_age );
		$( '.date-calculate-contributions' ).fdatepicker().trigger( 'changeDate' );
	});*/
	
	
});


// Check for Shortcode generated sliders and add bullets
if ( $( '.shortcode-slider' ).length > 0 ) {
	$( '.bullets-wrapper button ').remove(); // Remove any placeholder bullets
	$( '.shortcode-slider .orbit-slide' ).each(function( index ) {
		if ( index == 0 ) {
			$( '.bullets-wrapper' ).append( '<button class="is-active" data-slide="' + index + '"><span class="show-for-sr">Slide #' + index + '.</span><span class="show-for-sr">Current Slide</span></button>' );
		} else {
			$( '.bullets-wrapper' ).append( '<button data-slide="' + index + '" class=""><span class="show-for-sr">Slide #' + index + '.</span></button>' );
		}
		//<button data-slide="1"><span class="show-for-sr">Second slide details.</span></button>
		//<button class="is-active" data-slide="0"><span class="show-for-sr">First slide details.</span><span class="show-for-sr">Current Slide</span></button>
	});
}

$( '.numeric_only' ).keyup( function( event ) {
    var val = $( this ).val();
    if ( isNaN( val ) ) {
         val = val.replace(/[^0-9\.]/g, '' );
         if ( val.split( '.' ).length > 2 ) { 
             val = val.replace(/\.+$/, '' );
         }
    }
    $( this ).val( val.replace( '-', '' ) ); 
});


// Calculate Field Totals...
$( 'body' ).on( 'keyup', '.calculate_total input.count', function( event ) {
	var parent = $( this ).closest( '.calculate_total' );
	var total_field = parent.find( '#total' );
	var total = 0;
	parent.find( 'input.count' ).each(function() {
		total = total + Number( $( this ).val() );
    });
	
	if ( total > 100 ) {
		total_field.addClass( 'warning' );
	} else {
		total_field.removeClass( 'warning' );
	}
	
	console.log( 'total: ' + total );
	total_field.val( total );
});

/*
 * Main Menu - Big Subnav Menus
 */
/*$( '.main_nav_menu li' ).hover(
	function () {
		var subnav_id = $( this ).attr( 'id' );
		var $el = $( this );  
		var bottom = ( $el.offset().top + $el.outerHeight(true) ) - 4;
		console.log( 'bottom: ' + bottom );
		$( '#parent-' + subnav_id ).css( 'top', bottom+'px' );
		$( '#parent-' + subnav_id ).show();
	},
	function () {
		console.log( 'hover out ' );
		var subnav_id = $( this ).attr( 'id' );
		//setTimeout( hideSubMenu( subnav_id ), 500 );
		//setTimeout( hideSubMenu, 500, subnav_id );
		//hideSubMenu( subnav_id );
		//$( '#parent-' + subnav_id ).hide();
	}
);*/

$( 'body' ).on( 'click', '.main_nav_menu li a, .main_nav_menu_secondary li a', function( event ) {
	if ( Foundation.MediaQuery.atLeast( 'medium' ) ) {
		
		if ( $( this ).parent().hasClass( 'menu-item-has-children' ) ) {
			if ( $( this ).parent().hasClass( 'menu-active' ) ) {
				$( '.sub-menu-container' ).hide();
				$( '.main_nav_menu li' ).removeClass( 'menu-active' );
			} else {
				var subnav_id = $( this ).parent().attr( 'id' );
				var $el = $( this );  
				var bottom = $el.offset().top + $el.outerHeight(true);
				//console.log( 'bottom: ' + bottom );
				$( '#parent-' + subnav_id ).css( 'top', bottom+'px' );
				$( '.main_nav_menu li' ).removeClass( 'menu-active' );
				$( this ).parent().addClass( 'menu-active' );
				$( '.sub-menu-container' ).hide();
				$( '#parent-' + subnav_id ).show();
				event.preventDefault();
			}
			event.preventDefault();
		}
		
	}
    
});

/*$( '.sub-menu-container' ).mouseout(function() {
	setTimeout( hideAllSubMenus, 500 );
});*/

$( '.sub-menu-container' ).hover(
	function () {
	
	},
	function () {
		setTimeout( hideAllSubMenus, 250 );

	}
);

/*$( '.sub-menu-container' ).hover(
	function () {
		$( this ).addClass( 'active' );
	},
	function () {
		$( this ).removeClass( 'active' );
	}
);*/

function hideAllSubMenus() {
	$( '.sub-menu-container' ).hide();
	$( '.main_nav_menu li' ).removeClass( 'menu-active' );
}

/*function hideSubMenu( subnav_id ) {
	if ( !$( '#parent-' + subnav_id ).hasClass( 'active' ) ) {
		$( '#parent-' + subnav_id ).hide();
	}
}*/


/*
 * Image Zoom Function
 * Note: This feature is intended to work on non-touch devices only,
 * the related CSS in `image_zoom.scss` limits this functionality.
 */
function imageZoom( event ) {
	console.log('imageZoom function...');
	var zoomer = event.currentTarget;
	var offsetX = event.offsetX;
	var offsetY = event.offsetY;
	event.offsetX ? offsetX = event.offsetX : offsetX = event.touches[0].pageX;
	event.offsetY ? offsetY = event.offsetY : offsetY = event.touches[0].pageY;
	var x = offsetX / zoomer.offsetWidth * 100;
	var y = offsetY / zoomer.offsetHeight * 100;
	zoomer.style.backgroundPosition = x + '% ' + y + '%';
}

// Find all 'image_zoom' images on the page and add the image as a CSS background to its containing element
$( '.image_zoom figure' ).each(function( index ) {
	var bgimage = $( this ).children().attr( 'src' );
	console.log( bgimage );
	$( this ).css( 'background-image', 'url(' + bgimage + ')' );
});

// Find all images inside 'zoom_image_in_table' tables, wrap them in figure elements and add the image as a CSS background to its containing element
$( '.zoom_image_in_table td img' ).each(function( index ) {
	var bgimage = $( this ).attr( 'src' );
	$( this ).wrap(function() {
		return '<div class="wp-block-image image_zoom"><figure class="image_zoom" style="background-image: url(' + bgimage + ');"></figure></div>';
	});
});

// Mousemove over images
$( '.image_zoom figure' ).mousemove(function( event ) {
	if ( $( 'html' ).attr( 'data-whatinput') == 'mouse' || $( 'html' ).attr( 'data-whatinput') == 'keyboard' ) {
		imageZoom( event );
	}
});






$( 'body' ).on( 'click', '.sticky.is-stuck h1 a, .sticky.is-anchored .mobile_trigger button', function( event ) {
//$( 'body' ).on( '.sticky.is-stuck h1 a', 'click', function( event ) {
	if ( $( this ).hasClass( 'close_state' ) ) {
		$( '.subnav' ).css( 'display', 'none' );
		$( '.sticky h1 a' ).removeClass( 'close_state' );
		$( '.sticky .mobile_trigger button' ).removeClass( 'close_state' );
	} else {
		$( '.subnav' ).css( 'display', 'block' );
		$( '.sticky h1 a' ).addClass( 'close_state' );
		$( '.sticky .mobile_trigger button' ).addClass( 'close_state' );
	}
    event.preventDefault();
});

/*$( '.sticky' ).on( 'sticky.zf.unstuckfrom:top', function() {
 	$( '.subnav' ).css( 'display', 'block' );
});*/


$( '.sticky' ).on( 'sticky.zf.stuckto:top', function() {
	if ( Foundation.MediaQuery.atLeast( 'medium' ) ) {
		$( '.subnav' ).css( 'display', 'none' );
		$( '.sticky.is-stuck h1 a' ).removeClass( 'close_state' );
		$( '.sticky .mobile_trigger button' ).removeClass( 'close_state' );
	}
	
}).on('sticky.zf.unstuckfrom:top', function() {
	if ( Foundation.MediaQuery.atLeast( 'medium' ) ) {
		$( '.subnav' ).css( 'display', 'block' );
		$( '.sticky.is-stuck h1 a' ).addClass( 'close_state' );
		$( '.sticky .mobile_trigger button' ).addClass( 'close_state' );
	}
});

$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
  	// newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
	if ( newSize != 'small' ) {
		$( '.subnav' ).css( 'display', 'block' );
		$( '.sticky.is-stuck h1 a' ).addClass( 'close_state' );
		$( '.sticky .mobile_trigger button' ).addClass( 'close_state' );
	}
	
	console.log( 'newSize: ' + newSize);
	
	if ( newSize == 'small' ) {
		$( '.subnav' ).css( 'display', 'none' );
		$( '.sticky.is-stuck h1 a' ).removeClass( 'close_state' );
		$( '.sticky .mobile_trigger button' ).removeClass( 'close_state' );
	}
});


/*
 * DEADLINE CALCULATOR
 */

// step_1
//.step_1_no_outcome
//.step_1_yes_step2
//.step_1_yes_step2_no_outcome
//.step_1_yes_step2_yes_step3
//.step_1_yes_step2_yes_step3_no_outcome
//.step_1_yes_step2_yes_step3_yes_outcome
// Trigger by Click on Next button

// Step 1 > No > Outcome
$( 'body' ).on( 'click', '.deadline-calculator-item.step_1 .deadline-calculator-item__buttons-no', function( event ) {
	$( '.step_1_no_outcome' ).show();
	$( '.step_1_yes_step2' ).hide();
	$( this ).addClass( 'active' );
	$( '.deadline-calculator-item.step_1 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	
	$( '.step_1_yes_step2_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3' ).hide();
	
	$( '.step_1_yes_step2_yes_step3_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3_yes_outcome' ).hide();
	event.preventDefault();
});

// Step 1 > Yes > Step 2
$( 'body' ).on( 'click', '.deadline-calculator-item.step_1 .deadline-calculator-item__buttons-yes', function( event ) {
	$( '.step_1_no_outcome' ).hide();
	$( '.step_1_yes_step2' ).show();
	$( this ).addClass( 'active' );
	$( '.deadline-calculator-item.step_1 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	
	$( '.step_1_yes_step2_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3' ).hide();
	$( '.deadline-calculator-item.step_1_yes_step2 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	
	$( '.step_1_yes_step2_yes_step3_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3_yes_outcome' ).hide();
	
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	event.preventDefault();
});

// Step 1 > Yes > Step 2 > No > Outcome
// No
$( 'body' ).on( 'click', '.deadline-calculator-item.step_1_yes_step2 .deadline-calculator-item__buttons-no', function( event ) {
	$( '.step_1_yes_step2_no_outcome' ).show();
	$( '.step_1_yes_step2_yes_step3' ).hide();
	$( this ).addClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	
	$( '.step_1_yes_step2_yes_step3_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3_yes_outcome' ).hide();
	
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	event.preventDefault();
});
// Yes
$( 'body' ).on( 'click', '.deadline-calculator-item.step_1_yes_step2 .deadline-calculator-item__buttons-yes', function( event ) {
	$( '.step_1_yes_step2_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3' ).show();
	$( this ).addClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	
	$( '.step_1_yes_step2_yes_step3_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3_yes_outcome' ).hide();
	
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	event.preventDefault();
});

// Step 1 > Yes > Step 2 > Yes > Step 3
// No
$( 'body' ).on( 'click', '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-no', function( event ) {
	$( '.step_1_yes_step2_yes_step3_no_outcome' ).show();
	$( '.step_1_yes_step2_yes_step3_yes_outcome' ).hide();
	$( this ).addClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-yes' ).removeClass( 'active' );
	event.preventDefault();
});

// Yes
$( 'body' ).on( 'click', '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-yes', function( event ) {
	$( '.step_1_yes_step2_yes_step3_no_outcome' ).hide();
	$( '.step_1_yes_step2_yes_step3_yes_outcome' ).show();
	$( this ).addClass( 'active' );
	$( '.deadline-calculator-item.step_1_yes_step2_yes_step3 .deadline-calculator-item__buttons-no' ).removeClass( 'active' );
	event.preventDefault();
});

// Step 1 > Yes > Step 2 > Yes > Step 3 > No > Outcome


// Step 1 > Yes > Step 2 > Yes > Step 3 > Yes > Outcome















